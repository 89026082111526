<template>
  <b-table
    @sort-changed="sortChanged"
    :fields="fields"
    :items="items"
    :busy="busy"
    :stickyHeader="false"
    :responsive="true"
    :bordered="bordered"
    :borderless="borderless"
    :striped="striped"
    :outlined="outlined"
    :small="small"
    :hover="hover"
    :dark="dark"
    :fixed="fixed"
    :footClone="footClone"
    :noBorderCollapse="noCollapse"
    :empty-text="$t('general.noRecord')"
    :empty-filtered-text="$t('general.noRecord')"
    :no-local-sorting="true"
    show-empty
    class="list-table"
    :class="{
      'archived': archived,
    }"
  >
    <template #cell(increasement)="data">
      <b-link class="link-underline" @click="view(data.item)" v-if="viewModal">
        {{ calculateOrderNumber(data.index) }}
      </b-link>
      <span v-else>
        {{ calculateOrderNumber(data.index) }}
      </span>
    </template>

    <template #cell(createdAt)="data">
      {{ data.item.createdAt | formatDate }}
    </template>
    <template #cell(updatedAt)="data">
      {{ data.item.updatedAt | formatDate }}
    </template>
    <template #cell(deletedAt)="data">
      {{ data.item.deletedAt | formatDate }}
    </template>
    <template #cell(createdBy)="data">
      <b-link
        class="link-underline"
        :to="{
          name:
            data.item.createdBy.typeId == 2
              ? 'view-employee'
              : 'view-administrator',
          params: { id: data.item.createdBy.id },
        }"
        v-if="data.item.createdBy"
      >
        {{ data.item.createdBy.name }}
      </b-link>
    </template>
    <template #cell(updatedBy)="data">
      <b-link
        class="link-underline"
        :to="{
          name:
            data.item.updatedBy.typeId == 2
              ? 'view-employee'
              : 'view-administrator',
          params: { id: data.item.updatedBy.id },
        }"
        v-if="data.item.updatedBy"
      >
        {{ data.item.updatedBy.name }}
      </b-link>
    </template>
    <template #cell(deletedBy)="data">
      <b-link
        class="link-underline"
        :to="{
          name:
            data.item.deletedBy.typeId == 2
              ? 'view-employee'
              : 'view-administrator',
          params: { id: data.item.deletedBy.id },
        }"
        v-if="data.item.deletedBy"
      >
        {{ data.item.deletedBy.name }}
      </b-link>
    </template>
    <template #cell(actions)="data">
      <div class="text-nowrap">
        <slot name="front-button" :item="data.item"></slot>
        <b-button
          :to="{
            name: `view-${type}`,
            params: { id: data.item.id },
          }"
          v-b-tooltip.hover
          :title="$t('general.view')"
          variant="primary"
          pill
          size="sm"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
        <b-button
          v-if="$can('create', type) && clonable && !archived"
          :to="{
            name: `create-${type}`,
            query: { id: data.item.id },
          }"
          v-b-tooltip.hover
          :title="$t('general.clone')"
          variant="primary"
          pill
          size="sm"
        >
          <feather-icon icon="CopyIcon" />
        </b-button>
        <b-button
          v-if="$can('update', type) && updatable && !archived"
          :to="{
            name: `update-${type}`,
            params: { id: data.item.id },
          }"
          v-b-tooltip.hover
          :title="$t('general.update')"
          variant="primary"
          pill
          size="sm"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          v-if="$can('delete', type) && deletable && !archived"
          v-b-tooltip.hover
          :title="$t('general.delete')"
          variant="danger"
          pill
          size="sm"
          @click="destroy(data.item.id, data.index)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
        <b-button
          v-if="$can('restore', type) && deletable && archived"
          v-b-tooltip.hover
          :title="$t('general.restore')"
          variant="success"
          pill
          size="sm"
          @click="restore(data.item.id, data.index)"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </div>
    </template>

    <template #table-busy>
      <div class="text-center my-1">
        <b-skeleton-table
          :rows="10"
          :columns="fields.length"
          :hide-header="true"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </div>
    </template>

    <template #head()="scope">
      <div class="text-nowrap">{{ $t(scope.label) }}</div>
    </template>

    <template #cell()="scope">
      <div class="text-nowrap" v-if="scope.field.type == 'image'">
        <img
          class="cursor-pointer"
          v-if="scope.item[scope.field.key]"
          :width="scope.field.width ? scope.field : `50px`"
          :src="scope.item[scope.field.key]"
          :alt="scope.field.key"
          @click="previewImage(scope.item[scope.field.key])"
        />
      </div>
      <div class="text-nowrap" v-else>
        {{ scope.item[scope.field.key] }}
      </div>
    </template>

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </b-table>
</template>

<script>
import {
  BTable,
  BSkeletonTable,
  BSpinner,
  BLink,
  BButton,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BTable,
    BSkeletonTable,
    BSpinner,
    BLink,
    BButton,
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: function () {
        return this.$store.state.pagination.perPage;
      },
    },
    clonable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    viewModal: {
      type: Boolean,
      default: false,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      item: {},
    };
  },
  methods: {
    view(item) {
      this.$emit("view", item);
    },
    previewImage(image) {
      const preview = this.$imagePreview({
        initIndex: 0,
        images: [image],
        zIndex: 9999,
        isEnableDownloadImage: false,
        isEnableImagePageIndicator: false,
        isEnableBlurBackground: true,
        onClose: () => {},
      });
    },
    calculateOrderNumber(index) {
      const from = (this.currentPage - 1) * this.perPage + 1;
      return from + index;
    },
    submit() {
      this.$emit("submit");
    },
    sortChanged(ctx) {
      const item = this.fields.find((value) => {
        if (value.key === ctx.sortBy) {
          return value;
        }
      });

      if (!item) {
        return;
      }

      const sortBy = item?.sortField ? item.sortField : item?.key;
      const sortDirection = ctx.sortDesc ? "desc" : "asc";
      this.$emit("sort-changed", sortBy, sortDirection);
    },
    destroy(id, index) {
      index = this.calculateOrderNumber(index);

      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterDelete") + ` #${index}`, {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("button.yesDelete"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("destroy", id);
          }
        });
    },
    restore(id, index) {
      index = this.calculateOrderNumber(index);

      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterRestore") + ` #${index}`, {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("button.yesRestore"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("restore", id);
          }
        });
    },
  },
  setup() {
    // App Name
    const {
      bordered,
      borderless,
      striped,
      outlined,
      small,
      hover,
      dark,
      fixed,
      footClone,
      noCollapse,
    } = $themeConfig.table;
    return {
      bordered,
      borderless,
      striped,
      outlined,
      small,
      hover,
      dark,
      fixed,
      footClone,
      noCollapse,
    };
  },
};
</script>

<style scoped>
</style>
