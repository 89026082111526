<template>
  <cleave
    :value="value"
    class="form-control"
    :raw="raw"
    :options="date"
    placeholder="YYYY-MM-DD"
    @input="input"
    @blur="blur"
  />
</template>

<script>
import Cleave from "vue-cleave-component";

export default {
  components: {
    Cleave,
  },
  props: {
    value: {},
    raw: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: {
        date: true,
        delimiter: "-",
        datePattern: ["Y", "m", "d"],
      },
    };
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
    blur(value) {
      this.$emit("blur", value);
    },
  },
};
</script>