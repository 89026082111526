<template>
  <b-row v-if="total > 0">
    <b-col
      cols="12"
      sm="6"
      class="
        d-flex
        align-items-center
        justify-content-center justify-content-sm-start mb-2 pl-3
      "
    >
      <span class="text-muted">{{
        $t("pagination.showing", { from: from, to: to, total: total })
      }}</span>
    </b-col>
    <!-- Pagination -->
    <b-col
      cols="12"
      sm="6"
      class="
        d-flex
        align-items-center
        justify-content-center justify-content-sm-end  mb-2 pr-3
      "
    >
      <b-pagination
        :value="currentPage"
        :total-rows="total"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="pageChanged"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BPagination } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: function () {
        return this.$store.state.pagination.perPage;
      },
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: this.page,
      from: 0,
      to: 0,
    };
  },
  watch: {
    total() {
      this.setPaginationLabel();
    },
    perPage() {
      this.setPaginationLabel();
    },
  },
  methods: {
    reset() {
      this.currentPage = 1;
      this.setPaginationLabel();
    },
    pageChanged(page) {
      this.currentPage = page;
      this.setPaginationLabel();
      this.$emit("change", page);
    },
    setPaginationLabel() {
      this.from = (this.currentPage - 1) * this.perPage + 1;
      let to = this.currentPage * this.perPage;
      if (to > this.total) {
        to = this.total;
      }
      this.to = to;
    },
  },
};
</script>