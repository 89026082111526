var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"list-table",class:{
    'archived': _vm.archived,
  },attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.busy,"stickyHeader":false,"responsive":true,"bordered":_vm.bordered,"borderless":_vm.borderless,"striped":_vm.striped,"outlined":_vm.outlined,"small":_vm.small,"hover":_vm.hover,"dark":_vm.dark,"fixed":_vm.fixed,"footClone":_vm.footClone,"noBorderCollapse":_vm.noCollapse,"empty-text":_vm.$t('general.noRecord'),"empty-filtered-text":_vm.$t('general.noRecord'),"no-local-sorting":true,"show-empty":""},on:{"sort-changed":_vm.sortChanged},scopedSlots:_vm._u([{key:"cell(increasement)",fn:function(data){return [(_vm.viewModal)?_c('b-link',{staticClass:"link-underline",on:{"click":function($event){return _vm.view(data.item)}}},[_vm._v(" "+_vm._s(_vm.calculateOrderNumber(data.index))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.calculateOrderNumber(data.index))+" ")])]}},{key:"cell(createdAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.createdAt))+" ")]}},{key:"cell(updatedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.updatedAt))+" ")]}},{key:"cell(deletedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.deletedAt))+" ")]}},{key:"cell(createdBy)",fn:function(data){return [(data.item.createdBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
        name:
          data.item.createdBy.typeId == 2
            ? 'view-employee'
            : 'view-administrator',
        params: { id: data.item.createdBy.id },
      }}},[_vm._v(" "+_vm._s(data.item.createdBy.name)+" ")]):_vm._e()]}},{key:"cell(updatedBy)",fn:function(data){return [(data.item.updatedBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
        name:
          data.item.updatedBy.typeId == 2
            ? 'view-employee'
            : 'view-administrator',
        params: { id: data.item.updatedBy.id },
      }}},[_vm._v(" "+_vm._s(data.item.updatedBy.name)+" ")]):_vm._e()]}},{key:"cell(deletedBy)",fn:function(data){return [(data.item.deletedBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
        name:
          data.item.deletedBy.typeId == 2
            ? 'view-employee'
            : 'view-administrator',
        params: { id: data.item.deletedBy.id },
      }}},[_vm._v(" "+_vm._s(data.item.deletedBy.name)+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._t("front-button",null,{"item":data.item}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
          name: ("view-" + _vm.type),
          params: { id: data.item.id },
        },"title":_vm.$t('general.view'),"variant":"primary","pill":"","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),(_vm.$can('create', _vm.type) && _vm.clonable && !_vm.archived)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
          name: ("create-" + _vm.type),
          query: { id: data.item.id },
        },"title":_vm.$t('general.clone'),"variant":"primary","pill":"","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1):_vm._e(),(_vm.$can('update', _vm.type) && _vm.updatable && !_vm.archived)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
          name: ("update-" + _vm.type),
          params: { id: data.item.id },
        },"title":_vm.$t('general.update'),"variant":"primary","pill":"","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1):_vm._e(),(_vm.$can('delete', _vm.type) && _vm.deletable && !_vm.archived)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('general.delete'),"variant":"danger","pill":"","size":"sm"},on:{"click":function($event){return _vm.destroy(data.item.id, data.index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e(),(_vm.$can('restore', _vm.type) && _vm.deletable && _vm.archived)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('general.restore'),"variant":"success","pill":"","size":"sm"},on:{"click":function($event){return _vm.restore(data.item.id, data.index)}}},[_c('feather-icon',{attrs:{"icon":"RepeatIcon"}})],1):_vm._e()],2)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-1"},[_c('b-skeleton-table',{attrs:{"rows":10,"columns":_vm.fields.length,"hide-header":true,"table-props":{ bordered: false, striped: true }}})],1)]},proxy:true},{key:"head()",fn:function(scope){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t(scope.label)))])]}},{key:"cell()",fn:function(scope){return [(scope.field.type == 'image')?_c('div',{staticClass:"text-nowrap"},[(scope.item[scope.field.key])?_c('img',{staticClass:"cursor-pointer",attrs:{"width":scope.field.width ? scope.field : "50px","src":scope.item[scope.field.key],"alt":scope.field.key},on:{"click":function($event){return _vm.previewImage(scope.item[scope.field.key])}}}):_vm._e()]):_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(scope.item[scope.field.key])+" ")])]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }